.add-btn-container>.btn-detail {
    padding: 10px 45px;
}

@media (max-width:1400px) {}

@media(max-width: 1366px) {
    h2 {
        font-size: 28px !important;
    }
    .invoice-head.custom-class tr th {
        padding-block: -0px !important;
        font-size: 0.5px;
        letter-spacing: 5px
    }
    .Dashboardpage-links li {
        font-size: 16px !important;
        margin-top: 10px !important;
    }
    .Dashboardpage-links li a {
        margin-top: 10px !important;
    }
    .Dashboardpage-section .Dashboardpage-links {
        height: auto !important;
    }
    h5 {
        font-size: 25px !important;
    }
    .invoice-head {
        font-size: 15px !important;
    }
    .invoice-row {
        font-size: 12px !important;
    }
    .Dashboardpage-links ul {
        margin-left: -16px;
    }
    .Dashboardpage-links li a {
        white-space: nowrap;
    }
}
