.Dashboardpage-section {
  margin: 30px;
}

.Dashboardpage-section .Dashboardpage-links {
  width: 100%;
  /* height: 960px; */
  /* height: 100%; */
  box-shadow: 0px 1px 6px #1cb735;
  border-radius: 10px;
  padding: 20px;
}

.Dashboardpage-links ul {
  list-style-type: none;
  padding: 0;
}

.Dashboardpage-links li a {
  width: 100%;
  /* height: 50px; */
  text-decoration: none;
  color: black;
  margin-top: 30px;
  padding: 10px 8px;
  text-align: inherit;
  overflow-x: scroll;
}

.Dashboardpage-links li a::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

/* Track */
.Dashboardpage-links li a::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.Dashboardpage-links li a::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.Dashboardpage-links li a::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.active-link {
  background-color: #1cb735;
  color: #fff !important;
}

.Dashboardpage-links li a span {
  margin-right: 10px;
}

.Dashboardpage-project,
.Dashboardpage-invoice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.Dashboardpage-project-btn button,
.Dashboardpage-invoice-btn button {
  /* width: 140px;
  height: 50px; */
  padding: 12px 20px;
}

.project-title .price {
  margin-left: 10px;
}

.project-title {
  padding: 20px;
  box-shadow: 1px 4px 10px grey;
  margin: 20px 0;
  border-radius: 18px;
}

/* .invoice-head {
  color: #fff;
  background-color: #1cb735;
  text-align: center;
  border-radius: 15px 15px 0px 0px !important;
  font-size: 16px;
} */

.invoice-Table table {
  width: 100%;
}

/* .invoice-head tr th {
  padding: 10px;
} */

/* .invoice-row {
  text-align: center;
  box-shadow: 0px 0px 2px green;
  padding: 10px;
  font-size: 15px;
} */

/* .invoice-row td {
  padding: 10px;
} */

.invoice-row span img {
  margin-left: 5px;
}

.check-icon {
  color: #1cb735;
  font-size: 25px;
}

.close-icon {
  color: red;
  font-size: 20px;
}

.message-container {
  background-color: #4a4a4a;
  color: #fff;
  height: 785px;
  padding: 20px;
  border-radius: 15px;
  position: relative;
}

.message-box {
  color: #fff;
  display: flex;
  position: relative;
  padding-bottom: 5%;
}

.gray {
  color: #a8a8a8;
}

.message-box-img {
  width: 59px;
  height: 59px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.message-box-img img {
  width: 41.93px;
  height: 20.45px;
}

.xsmall {
  font-size: 14px;
}

.Dashboardpage-section .line-img {
  position: absolute;
  width: 100%;
  left: 0px;
  right: 0px;
  bottom: 0%;
  /* z-index: -1; */
}

.left-box {
  background-color: #707070;
  padding: 20px;
  margin: 10px 0;
}

.left-message-box {
  margin: 20px 0;
}

.right-message-box {
  float: right;
}

.right-message-box .gray {
  text-align: right;
}

.messagebox-input {
  position: absolute;
  bottom: 0;
  left: 10px;
  right: 10px;
}

.form {
  position: relative;
}

.send-icon {
  position: absolute;
  right: 15px;
  top: 40%;
  font-size: 20px;
}

.messagebox-input .inputbox {
  background-color: #a8a8a8;
  outline: none;
  border: none;
}

.messagebox-input .inputbox::placeholder {
  color: #fff;
}

/* red-progress-bar */

.red-progress-bar {
  background-color: red;
}
