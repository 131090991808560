.are-you-sure-modal {
}

/* .are-you-sure-modal .modal-box {
  border: none;
  margin-top: 12%;
    margin-bottom: 5%;
}
.are-you-sure-modal .modal-box h5 {
  margin-bottom: 5%;
  text-align: center;
  font-size: 30px;
} */
.are-you-sure-modal .modal-box {
  /* border: none;
  margin-top: 12%;
    margin-bottom: 5%; */
    border: none;
    /* width: 80%; */
    margin: auto;
    margin-top: 10%;
    /* margin-bottom: 10%; */
}
.modalbox2{
   margin-bottom: 10% !important;
   width: 80% !important;
}
.are-you-sure-modal .modal-box h5 {
  margin-bottom: 5%;
  text-align: center;
  font-size: 18px;
}
.are-you-sure-modal .modal-box .btns-box {
  margin-top: 5%;
  display: flex;
  justify-content: center;
}

.are-you-sure-modal .modal-box .btns-box button {
  outline: none;
  border: none;
}

.are-you-sure-modal .modal-box .btns-box .no-btn {
  background-color: #1CB735;
  color: #ffffff;
  border-radius: 8px;
  font-size: 18px;
  padding: 8px 30px;
}
.are-you-sure-modal .modal-box .btns-box .yes-btn {
  background-color: #1CB735;
  color: #ffffff;
  border-radius: 8px;
  font-size: 18px;
  padding: 8px 30px;
  margin-left: 3%;
}

.modal-close-icon {
  float: right;
  padding: 3px 5px;
  background-color: #1CB735;
  color: #ffffff;
  border-radius: 8px;
}
.modal-close-icon .icon {
  width: 25px;
  height: 25px;
}
.modal-Header{
  background-color: #1CB735;
  color: #ffffff;
  border-radius: 20px 20px 0px 0px;
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 170px;
}
