:root {
    --pink-color: #8F8F8F;
    --green-color: #1CB735;
    --red-color: #D80505;
    --white-color: #fff;
}

.withdrawl_Title {
    width: 70%;
}

.projectTitle {
    margin-bottom: 50px;
    padding-left: 20px;
}

.withdrawl_Title ul {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: 0;
    flex-wrap: wrap;
}

.withdrawl_Title ul li {
    list-style: none;
}

.projectTittle_description {
    box-shadow: 0px 1px 6px #1cb735;
    padding: 26px 60px;
    word-spacing: 8px;
    border-radius: 15px;
}

.projectTittle_description p:nth-child(2) {
    color: var(--pink-color);
}

.Withdrawl_btns {
    display: flex;
    justify-content: end;
    margin-top: 80px;
}

.Withdrawl_btns .accept {
    padding: 10px 30px;
    background: var(--green-color);
    color: var(--white-color);
    border-radius: 5px;
    margin-right: 25px;
}

.Withdrawl_btns .reject {
    padding: 10px 30px;
    background: var(--red-color);
    color: var(--white-color);
    border-radius: 5px;
}