.Notification-heading {
  padding: 20px;
  border-bottom: 1px solid rgba(195, 195, 195, 0.5);
}

.Notification-body {
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid rgba(195, 195, 195, 0.5);
  cursor: pointer;
  transition: 0.4s;
}
.Notification-body:hover {
  background-color: rgba(195, 195, 195, 0.5);
  transition: 0.4s;
}
.Notification-avatar {
  object-fit: cover;
  display: flex;
}

.Notification-avatar img {
  object-fit: cover;
  width: 70px;
  height: 70px;
  border-radius: 50px;
}

.Notification-text {
  margin-left: 20px;
  font-size: 18px;
}


.Notification-text span {
  
  font-size: 13px;
  color: #9d8f8f;
  position: absolute;
  right: 10%;

}