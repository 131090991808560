.Footer-section {
  background-color: #292929;
  padding-top: 50px;
  padding-bottom: 20px;
}
.footer-leftside .mid {
  color: #a8a8a8;
  margin-top: 20px;
  margin-bottom: 30px;
}
.footer-leftside {
  max-width: 76%;
}
.social-icons {
  color: #1cb735;
  font-size: 30px;
  margin: 10px;
}
.footer-rightside a {
  text-decoration: none !important;
  color: #a8a8a8 !important;
}
.social-icons:hover {
  cursor: pointer;
}
.footer-middile,
.footer-rightside {
  margin-top: 50px;
}
.footer-middile h2,
.footer-rightside h2 {
  color: #ffff;
}
.footer-middile ul li a {
  text-decoration: none;
  color: #a8a8a8;
}
.footer-middile ul {
  list-style-type: none;
  padding: 5px;
}
.footer-middile ul li {
  padding: 10px;
}
.footer-rightside span {
  color: #a8a8a8;
  font-size: 18px;
}
.footer-rightside span .num {
  border: 1px solid wheat;
}
.copyright-text {
  text-align: center;
}
.copyright-text .mid {
  color: #a8a8a8;
  margin-top: 50px;
}
.con {
  display: flex;
  color: #a8a8a8;
  font-size: 18px;
}

p {
  word-break: break-word;
}

.exitBtn {
  position: absolute !important;
  right: 7%;
}

.markasReadBtmn {
  text-decoration: underline;
  color: rgb(28, 183, 53);
  cursor: pointer;
}

.w-113 {
  width: 113px !important;
}

.pdfMapperContainer {
  display: flex;
  flex-wrap: wrap;
}
.pdfMapperContainer p {
  display: block;
  margin-bottom: 0;
}

.pdfMapperContainer div {
  border: 1px solid #1cb735;
  padding: 10px;
  border-radius: 5px;
  margin-right: 10px;
  /* margin-bottom: 10px; */
  cursor: pointer;
  display: table;
  margin-top: 10px;
}

.pdfMapperContainer div svg {
  font-size: 20px;
}

.user-modal-img {
  width: 100px;
  margin-top: 15px;
}

.pending,
.Pending {
  color: #ffbf00 !important;
  text-transform: capitalize !important;
}

.underReview {
  color: purple !important;
  text-transform: capitalize !important;
}

.inProgress {
  color: red !important;
  text-transform: capitalize !important;
}

.completed,
.paid,
.Paid {
  color: #1cb735 !important;
  text-transform: capitalize !important;
}

.p-r {
  position: relative;
}

.password-eye {
  position: absolute;
  color: #1cb735;
  font-size: 35px;
  cursor: pointer;
  right: 40px;
  top: 5px;
}

.input-box {
  padding: 20px;
  margin-top: 10px !important;
}

.info-modal-x {
  width: 30% !important;
  padding: 0 !important;
  border: 0 !important;
  border-radius: 20px !important;
  height: 650px;
  overflow-y: scroll;
  border-radius: 12px !important;
}

/* width */
.info-modal-x::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.info-modal-x::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.info-modal-x::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.info-modal-x::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.loc-activated {
  color: #1cb735 !important;
}

.user-info-card-list {
  list-style-type: none;
  padding: 0;
}

.user-info-card-list a {
  text-decoration: none;
  font-size: unset;
  color: unset;
  font: unset;
}

.parser-para p {
  margin-top: 0 !important;
}

.modal-backrop {
  background: rgba(0, 0, 0, 0.7);
}
