.navbar-btn1 {
    /* width: 170px;
  height: 50px; */
    /* padding-left:  0 ;
  padding-right: 15px ; */
    padding: 5px 20px 5px 5px;
}

.navbarbtn-icon {
    font-size: 30px;
    margin-right: 5px;
    padding: 0;
}


/* .navbar-light .navbar-nav .nav-link {
  padding: 16px;
  color: #A8A8A8;
} */

.btn-disabled {
    background-color: rgba(28, 183, 53, 0.5) !important;
}

.t-t-c {
    text-transform: capitalize !important;
}

.innerImg img {
    border-radius: 10px;
}

.c-r {
    background-color: red !important;
}

.add-btn-container {
    display: flex;
    justify-content: flex-end;
}

.label-margin {
    margin: 0 60px;
}

.w-40 {
    height: 40px !important;
}

.crud-modal-image {
    width: 100%;
    object-fit: contain;
    height: 15vh;
    align-self: center;
}

.crud__ImagePicker {
    display: flex;
    flex-direction: column;
    width: 608px;
    margin: auto;
}


/* .sc-furwcr.ehGMMA {
  width: inherit;
} */

.btn-detail-x {
    margin: 33px 40px;
    padding: 15px 20px;
}

.customer-info-container-x {
    display: flex;
    justify-content: space-between;
}

.graph-dropdown-x {
    border: 1px solid gray;
    outline: none;
    padding: 3px;
    border-radius: 5px;
}

.crud-table-x {
    max-height: 68.5vh !important;
}

.invoice-head.custom-class {
    opacity: 0;
}

.invoice-head.custom-class>tr {
    height: 1px;
}

.invoice-head.custom-class tr th {
    padding-block: 0px !important;
    /* font-size: 0; */
    /* letter-spacing: 10px; */
    line-height: 1px;
}

.w-custom-new {
    width: 50px;
}