.Dashboardpage-section {
  margin: 30px;
}

.Dashboardpage-links {
  width: 100%;
  /* height: 839px; */
  box-shadow: 0px 1px 6px #1cb735;
  border-radius: 10px;
  padding: 20px;
}

.Dashboardpage-links ul {
  list-style-type: none;
  padding: 0;
}

.Dashboardpage-links li a {
  width: 100%;
  /* height: 50px; */
  text-decoration: none;
  color: black;
  margin-top: 30px;
  padding: 10px 20px;
  text-align: inherit;
}

.earning-black {
  color: black;
}

.active-link {
  background-color: #1cb735;
  color: #fff !important;
}

.Dashboardpage-links li a span {
  margin-right: 10px;
}

.Dashboardpage-project,
.Dashboardpage-invoice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 20px 0;
}

/* .Dashboardpage-project-btn button,
.Dashboardpage-invoice-btn button {
    width: 140px;
    height: 50px;
} */

.admin-message {
  margin: 20px 0;
}

.project-title .price {
  margin-left: 10px;
}

.project-title {
  padding: 20px;
  box-shadow: 1px 4px 10px grey;
  margin: 20px 0;
  border-radius: 18px;
}

.css-1s2u09g-control {
  border-color: #000;
}

/** Border Fix. **/
table {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
table th {
  border: none !important;
}

table td {
  border: none !important;
}

.invoice-Table table {
  width: 100%;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.invoice-head tr th {
  padding: 10px;
  color: white;
}

.invoice-head tr {
  /* outline: 1px solid #1CB735; */
  /* border-radius: 20px 20px 0 0 !important; */
}
.invoice-row {
  text-align: center;
  /* box-shadow: 0px 0px 2px green inset; */
  padding: 15px;
  font-size: 14px;
  /* border: 0.5px solid green; */
  -webkit-appearance: none;
  /* -webkit-box-shadow: 0px 0px 2px green inset; */
}

.invoice-row td {
  padding: 15px;
}
thead th {
  background-color: #1cb735;
}

thead th:first-child {
  border-radius: 6px 0 0 0;
}

thead th:last-child {
  border-radius: 0 6px 0 0;
}
/* tbody tr:last-child {
    border-radius: 0 0 20px 20px;
} */

/* .invoice-Table.invoive-table-x {
    overflow-x: scroll;
} */

.adminTablee {
  overflow-x: scroll;
}

.adminTablee::-webkit-scrollbar {
  width: 3px;
  height: 7px;
}

.adminTablee::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.adminTablee::-webkit-scrollbar-thumb {
  background: #888;
}

.adminTablee::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.descriptionQill {
  max-width: 608px;
  margin: auto;
  /* margin-bottom: 50px; */
  margin-top: 10px;
}

.invoive-tr-x td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.banking-btn {
  padding: 5px;
  font-size: 12px;
}

.invoice-row span img {
  margin-left: 5px;
}

.check-icon {
  color: #1cb735;
  font-size: 25px;
}

.close-icon {
  color: red;
  font-size: 20px;
}

.message-container {
  background-color: #4a4a4a;
  color: #fff;
  height: 785px;
  padding: 20px;
  border-radius: 15px;
  position: relative;
}

.message-box {
  color: #fff;
  display: flex;
}

.gray {
  color: #a8a8a8;
}

.message-box-img {
  width: 59px;
  height: 59px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.message-box-img img {
  width: 41.93px;
  height: 20.45px;
}

.xsmall {
  font-size: 14px;
}

.line-img {
  text-align: center;
}

.line-img img {
  width: 100%;
}

.left-box {
  background-color: #707070;
  padding: 20px;
  margin: 10px 0;
}

.left-message-box {
  margin: 20px 0;
}

.right-message-box {
  float: right;
}

.right-message-box .gray {
  text-align: right;
}

.messagebox-input {
  position: absolute;
  bottom: 0;
  left: 10px;
  right: 10px;
}

.form {
  position: relative;
}

.send-icon {
  position: absolute;
  right: 15px;
  top: 40%;
  font-size: 20px;
}

.messagebox-input .inputbox {
  background-color: #a8a8a8;
  outline: none;
  border: none;
}

.messagebox-input .inputbox::placeholder {
  color: #fff;
}

.question-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question-icon {
  font-size: 24px;
  color: #d3d8da;
}

.draft-links ul {
  list-style-type: none;
}

.draft-links ul li a {
  text-decoration: none;
  color: #8f8f8f;
  font-size: 12px;
}

.Budget-admin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 37px;
  position: relative;
}

.Budget-admin-img {
  position: absolute;
  left: 0;
}

.draft-links a {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;
}

.active-draft-link {
  border-radius: 20px;
  background-color: #ebf9ea;
  color: #1cb735 !important;
}

.xsmall {
  font-size: 14px;
}

.Budeget-admin h1 {
  font-size: 72px;
}

.projectsection .innerspan {
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress {
  width: 70px !important;
  /* margin-left: 5px; */
}

.value-box {
  width: 42px;
}
.bg-success {
  background-color: #1cb735 !important;
}

.budgetCard {
  box-shadow: 1px 2px 4px grey;
  border-radius: 8px;
  padding: 10px;
}

.admin-messsage-box {
  height: 337px;
  background-color: #4a4a4a;
  color: #fff;
  padding: 10px;
  border-radius: 12px;
}

.admin-messsage-box2 {
  height: 300px;
  overflow-y: scroll;
}

.admin-messsage-box2::-webkit-scrollbar {
  background: var(--dark-pink);
  width: 6px;
  height: 30%;
  border-radius: 19px;
}

.admin-messsage-box2::-webkit-scrollbar-thumb {
  background-color: #bfbebe;
}

.admin-messsage-box-card {
  display: flex;
  width: 99%;
  flex-direction: column;
}

.admin-messsage-innerbox {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* margin-left: 10px; */
}

.admin-messsage-innerbox1 {
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin-left: 10px;
}

.admin-messsage-box-card {
  background-color: #818181;
  border-radius: 12px;
  padding: 10px;
  margin-top: 10px;
}

.chart-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2%;
}

.chart-heading span {
  font-size: 18px;
}

.chart-heading span .down-icon {
  font-size: 30px;
  padding: 0;
}

.chart-card {
  box-shadow: 0px 1px 4px grey;
  border-radius: 12px;
  padding: 14px;
}

.c-p {
  cursor: pointer;
}

.detail_card {
  /* width: 70%; */
  /* padding: 42px; */
  padding: 40px 30px;
  box-shadow: 1px 1px 20px #00000021;
  border-radius: 20px;
  font-family: "poppins";
}
.payemets_consultants {
  max-width: 200px;
}
.payemets_consultants {
  max-width: 384px;
  word-break: break-word;
}
.new_headingSize .detail_card p.small {
  /* color: #8F8F8F; */
}

.btn-detail {
  /* padding: 15px 20px;
    margin: 20px 5px; */
  padding: 15px 10px;
  /* width: 100px; */
  margin: 20px 5px;
  font-size: 15px;
}

.modal-btn {
  padding: 10px 20px;
}

.modal-inp {
  padding: 12px 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid;
  width: 100%;
  font-size: 16px !important;
}

.css-tlfecz-indicatorContainer {
  color: #000;
}

.modal-content {
  border-radius: 15px;
  padding: 20px;
}

.modal-footer .modal-btn {
  margin: 0 auto;
}

.inner-text {
  font-size: 20px;
  line-height: 1.5;
  font-family: "Poppins Regular";
}

.bd {
  font-weight: bold;
}

.btn-box {
  display: flex;
  justify-content: flex-end;
}

.selectImg_box {
  width: 75%;
  flex-wrap: wrap;
  margin: 60px 0;
}

.selectimgs {
  width: 170px;
  height: 170px;
  border-radius: 10px;
  outline: 2px dotted #a8a8a8;
}

.selectimgs img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  border-radius: 10px;
}

.custmorDetail_innerText ul {
  /* display: flex;
    justify-content: space-between; */
  width: 60%;
  /* padding-left: 0; */
}

.custmorDetail_innerText ul li {
  /* list-style: none; */
  /* color: #8F8F8F; */
}

.custmorDetail_inner {
  background: #fefefe;
  padding: 40px 30px;
  box-shadow: 1px 1px 20px #00000021;
  border-radius: 15px;
}

.invoice-Table {
  overflow-x: auto;
  padding: 2px;
  /* overflow: scroll; */
}

.customer_detail_main h2 {
  margin: 35px 0px;
}

.custmorDetail_inner .innerImg {
  width: 80px;
  height: 80px;
  border-radius: 10px;
}

.custmorDetail_inner .innerImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}

.custmorDetail_inner .profileInnerImg {
  max-width: 12%;
}

.customerDetail_icon {
  color: #1cb735;
  padding-right: 2px;
}

.custmorDetail_innerText p.small {
  color: #8f8f8f;
}

.detailPage_table h3,
.detailPage_table h4 {
  margin: 60px 0 20px 0;
}

.assigned_freelancer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 40px;
  box-shadow: 1px 1px 20px #00000021;
  border-radius: 15px;
}

.customer_detail_main h3 {
  margin: 50px 0 20px 0;
}

.assigned_freelancer p.big {
  color: #33c534;
}

.select_pdf {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.select_pdf .pdf_icon {
  color: #d80505;
  font-size: 26px;
  margin-bottom: 8px;
}

.table-detail-btn > button {
  padding: 10px 8px;
  font-size: 12px;
}

.detail-box {
  padding: 20px;
  font-family: "poppins";
}

.detail-box h5 {
  font-size: 35px;
}

.detail-box h1,
p {
  margin-top: 20px;
}

.detail-inner-box {
  display: flex;
  justify-content: space-between;
}

.detail-inner-box p {
  width: 50%;
}

.box-descript {
  font-size: 20px;
  font-weight: 300;
}

.proposalDetailGeneralHeading {
  font-weight: bold;
  font-size: 25px;
}

.proposalDetailValueText {
  font-size: 20px;
}

.accepted,
.active {
  color: #0baf0b;
}

.rejected {
  color: #ed3f2c;
}

.breedCum {
  margin: 0 0 30px 0px;
  display: flex;
  align-items: center;
}

.breedCum svg {
  fill: black;
  margin: 0px 10px;
}

.breedCum span {
  font-size: 20px;
}

.edit-btn button {
  padding: 10px 40px;
}

.custom-image-upload {
  justify-content: start !important;
}

.save-btn {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.save-btn button {
  padding: 15px 50px;
}

a.btn.custom-btn.active-link img {
  filter: brightness(10);
}

.edit-img {
  width: 25px;
}

.funds-di {
  position: absolute;
  right: 1px;
  top: -5px;
  border: 2px solid #1cb735;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 600;
}
