.mainContainer {
  padding: 0 27px;
}

.mainContainer table {
  width: 100%;
  border-collapse: collapse;
}

.mainContainer tr td,
.mainContainer tr th {
  padding: 10px;
}

.mainContainer table thead th {
  background-color: #1cb735;
  color: white;
}

.cp {
    cursor: pointer;
}