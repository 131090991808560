.add-project-section {
  margin: 5% 0px;
}
.add-project-section {
}
.add-project-section h2 {
  text-align: center;
  margin-bottom: 2%;
}
.field-margin {
  margin-bottom: 2%;
}
.add-project-section .input {
  padding: 20px;
}
.add-project-section .btn-box {
  margin-top: 3%;
}
.add-project-section .btn-box button {
  padding: 15px 30px;
  background-color: #1cb735;
}


.body-loader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(175, 236, 182 , 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0px 10px 30px 0 rgba(43, 45, 66, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  z-index: 9999;
}